import gql from "graphql-tag";

export const GET_SUBVERTICAL_GLOBAL_CONFIG = gql`
  query GetSubVerticalGlobalConfig {
    LDPConfigQueryGroup {
      GetSubVerticalGlobalConfig
    }
  }
`;

export const MERGE_SUBVERTICAL_GLOBAL_CONFIG = gql`
  mutation MergeSubVerticalGlobalConfig(
    $mergeSubVerticalGlobalConfigParams: MergeSubVerticalGlobalConfigInputType!
  ) {
    LDPConfigMutationGroup {
      MergeSubVerticalGlobalConfig(
        MergeSubVerticalGlobalConfigParams: $mergeSubVerticalGlobalConfigParams
      )
    }
  }
`;
