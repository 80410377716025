import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import { ContentLoading, useModal } from "../../components";
import { IconButton, Grid, Box, Button } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { createDateFilter } from "../../components/customMuiDatatableFilter";
import { GoogleUserMeta } from "../../common/utils/googleUserMeta";
import { GET_SUBVERTICAL_GLOBAL_CONFIG } from "../../common/models/subverticalGlobalConfig";
import { GetSubVerticalGlobalConfig } from "../../common/models/types/GetSubVerticalGlobalConfig";
import { AddCircle, Edit as EditIcon } from "@material-ui/icons";
import SVGDModal from "./svgdModal";



const TAB_CONTAINER = "subvertical-global-defaults-list-tabs";

interface SubVerticalGlobalDefaultsListProps {
  SubVerticalGlobalDefaultsTab?: any;
  dispatch?: Function;
}

type SubVerticalGlobalConfig = {
  SubVerticalGlobalConfigId: number;
  CreatedDate: string;
  ModifiedDate: string;
  VerticalName: string;
  SubVerticalName:string;
  MaxNonExclusiveSaleCount: number;
  UserId: string;
}

const SubVerticalGlobalDefaultsList = ({ SubVerticalGlobalDefaultsTab, dispatch }: SubVerticalGlobalDefaultsListProps) => {
  const [getSubVerticalGlobalConfig, { data, error, loading, called, refetch }] = useLazyQuery<GetSubVerticalGlobalConfig>(GET_SUBVERTICAL_GLOBAL_CONFIG, { fetchPolicy: 'network-only' });
  const [subVerticalGlobalDefaultsList, setSubVerticalGlobalDefaultsList] = useState<SubVerticalGlobalConfig[]>([]);
  const googleUserMeta = GoogleUserMeta();
  const { Modal, closeModal, openModal, setContent } = useModal();

  const columnsMUI = [
    {
      name: "SubVerticalGlobalConfigId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {                        
          return (
            <IconButton
              onClick={() => {                
                openModal({
                  title: "Edit Subvertical Global Default",
                  icon: <EditIcon />,
                  iconColor: "green",
                  content: (
                    <SVGDModal
                      entryId={value}
                      rowData={Object.fromEntries(columnsMUI.map((it: any, index: number) => [it.name, tableMeta.rowData[index]]))}
                      close={() => {
                        closeModal();
                        if (refetch) refetch();
                        else getSubVerticalGlobalConfig();
                      }}
                      refetch={refetch}
                      dispatch={dispatch}
                      googleUserMeta={googleUserMeta}
                    />
                  ),
                });
              }}
            >
              <LaunchIcon />
            </IconButton>
          );
        },
      },
    },
    {
      name: "SubVerticalGlobalConfigId",
      label: "SubVertical Global Config Id",
      options: {
        viewColumns: true,
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        filter: true,        
        customBodyRender: (value: any) => {
            return value
            ? dateToPreferredTimezone(value, "yyyy-MM-dd")
            : "";
        },
        ...createDateFilter()
      }
    },
    {
      name: "ModifiedDate",
      label: "Modified Date",
      options: {
        filter: true,        
        customBodyRender: (value: any) => {
            return value
            ? dateToPreferredTimezone(value, "yyyy-MM-dd")
            : "";
        },
        ...createDateFilter()
      }
    },
    {
      name: "VerticalName",
      label: "Vertical",
      options: {
        viewColumns: true,
        filter: true,
        filterType: 'dropdown',
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: "SubVerticalName",
      label: "Sub-Vertical",
      options: {
        viewColumns: true,
        filter: true,
        filterType: 'dropdown',
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: "MaxNonExclusiveSaleCount",
      label: "MaxNon Exclusive Sale Count",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
    {
      name: "UserId",
      label: "UserId",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
  ];

  useEffect(() => {
    getSubVerticalGlobalConfig();
  }, []);

  useEffect(() => {
    if(data && !error){
      const list:SubVerticalGlobalConfig[] = JSON.parse(`${data.LDPConfigQueryGroup?.GetSubVerticalGlobalConfig}`);
      setSubVerticalGlobalDefaultsList(list);
    }
  }, [data]);

  const tableData = useMemo(() => {
    return [ ...(subVerticalGlobalDefaultsList || []) ];
  }, [subVerticalGlobalDefaultsList]);

  const options: any = {
    searchOpen: true,
    filterType: "dropdown",
    sortFilterList: false,
    selectableRows: "none",
    responsive: "vertical",        
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  openModal({
                    title: "Subvertical Global Default",
                    icon: <EditIcon />,
                    iconColor: "green",
                    content: (
                      <SVGDModal
                        close={() => {
                          closeModal();
                          refetch();
                        }}
                        refetch={refetch}
                        dispatch={dispatch}
                        googleUserMeta={googleUserMeta}
                      />
                    ),
                  });
                }}
              >
                Set Subvertical Global Default
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {loading && <ContentLoading showTip={true} />}
        <LDPUIDataTable
          ldpTableId="undersold-list"
          restoreFilters={true}
          title={""}
          data={tableData}
          columns={columnsMUI}
          options={options}
        />
      </Grid>
      <Modal />
    </Grid>
  );
};

export default connect((state: any) => ({
  SubVerticalGlobalDefaultsTab: state.tabsSection[TAB_CONTAINER],
}), null)(SubVerticalGlobalDefaultsList);

const Style = {
  Paper: {
    marginTop: "50px",
    padding: "30px",
    width: "100%",
  },
  TrafficLight: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    lineHeight: "24px",
    border: "#000 1px solid",
    borderRadius: "50%",
    margin: "auto 0",
  },
};
