import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AddCircle,
  Edit as EditIcon,
  History as HistoryIcon,
} from "@material-ui/icons";
import LaunchIcon from "@material-ui/icons/Launch";
import { DateTime } from "luxon";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { GET_BUYERS } from "../../../common/models/buyers";
import {
  DELETE_DEACTIVATION_SCHEDULE,
  GET_DEACTIVATION_SCHEDULE,
} from "../../../common/models/deactivationSchedule";
import { GetBuyers } from "../../../common/models/types/GetBuyers";
import {
  GetDeactivationSchedule,
  GetDeactivationSchedule_LDPConfigQueryGroup_GetDeactivationSchedule,
} from "../../../common/models/types/GetDeactivationSchedule";
import { dateToPreferredTimezone } from "../../../common/utils/date";
import {
  ContentLoading,
  StatusIcon,
  useConfirmation,
  useModal,
} from "../../../components";
import {
  booleanRadioFilter,
  createDateFilter,
} from "../../../components/customMuiDatatableFilter";
import LDPUIDataTable from "../../../components/LDPUIDataTable";
import HistoryModal from "../../history/HistoryModal";
import { DeactivationScheduleFormModal } from "./deactivationScheduleModal";

const TAB_CONTAINER = "deactivation-schedule-list-tabs";

interface DeactivationScheduleListProps {
  DeactivationScheduleTab?: any;
  dispatch?: Function;
}

const DeactivationScheduleList = ({
  DeactivationScheduleTab,
  dispatch,
}: DeactivationScheduleListProps) => {
  const [
    getDeactivationSchedule,
    { data, error, loading, called, refetch },
  ] = useLazyQuery<GetDeactivationSchedule>(GET_DEACTIVATION_SCHEDULE, {
    fetchPolicy: "network-only",
  });

  const [
    getBuyers,
    { data: buyerData, loading: loadingBuyers },
  ] = useLazyQuery<GetBuyers>(GET_BUYERS);

  const [deleteDeactivationScheduleByIDs] = useMutation(
    DELETE_DEACTIVATION_SCHEDULE
  );
  const [deactivationScheduleList, setDeactivationScheduleList] = useState<
    | (GetDeactivationSchedule_LDPConfigQueryGroup_GetDeactivationSchedule | null)[]
    | null
  >();

  const { Modal, closeModal, openModal } = useModal();
  const {
    Modal: Confirmation,
    closeModal: closeConfirmation,
    useModal: setConfirmation,
  } = useConfirmation();

  const buyerList = useMemo(() => buyerData?.LDPConfigQueryGroup?.Buyer || [], [
    buyerData,
  ]);
  const tableData = useMemo(() => {
    if (data && !error) {
      return data.LDPConfigQueryGroup?.GetDeactivationSchedule;
    }

    return [];
  }, [data, error]);

  const columnsMUI = useMemo(() => {
    return [
      {
        name: "DeactivationScheduleId",
        label: " ",
        options: {
          viewColumns: false,
          filter: false,
          customBodyRender: (value: any, tableMeta: any) => {
            return (
              <>
                <Tooltip
                  placement="top"
                  title={
                    <React.Fragment>
                      <Typography color="textPrimary">
                        Edit Deactivation Schedule
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <IconButton
                    onClick={() => {
                      const item: any = {};
                      columnsMUI.map((it, n) => {
                        item[it.name] = tableMeta.rowData[n];
                      });
                      openModal({
                        title: "Edit deactivation schedule",
                        icon: <EditIcon />,
                        iconColor: "green",
                        content: (
                          <DeactivationScheduleFormModal
                            action="update"
                            data={item}
                            close={closeModal}
                            refetch={refetch}
                          />
                        ),
                      });
                    }}
                  >
                    <LaunchIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  placement="top"
                  title={
                    <React.Fragment>
                      <Typography color="textPrimary">History</Typography>
                    </React.Fragment>
                  }
                >
                  <IconButton
                    onClick={() => {
                      openModal({
                        title: `Deactivation Schedule ID#${value} History`,
                        icon: <HistoryIcon />,
                        iconColor: "orange",
                        customHeaderButtons: <></>,
                        content: () => {
                          const TAB_CONTAINER_ID = `deactivation-schedule-history-${value}-list-tabs`;
                          return (
                            <HistoryModal
                              ConfigName="DeactivationSchedule"
                              ConfigId={value}
                              TabContainerId={TAB_CONTAINER_ID}
                              close={closeModal}
                              isDocker={false}
                              formId={`deactivation-schedule-history-${value}`}
                              // HistoryListComponent={<DeactivationScheduleHistoryList DeactivationScheduleId={value} TabContainerId={TAB_CONTAINER_ID} />}
                            />
                          );
                        },
                      });
                    }}
                  >
                    <HistoryIcon />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
        },
      },
      {
        name: "DeactivationScheduleId",
        label: "Deactivation Schedule Id",
        options: {
          viewColumns: true,
        },
      },
      {
        name: "Name",
        label: "Deactivation Name",
        options: {
          viewColumns: true,
          filter: true,
          customBodyRender: (value: any) => {
            return value ? value : "";
          },
        },
      },
      {
        name: "ModifiedDate",
        label: "Modified Date",
        options: {
          filter: true,
          customBodyRender: (value: any) => {
            return value
              ? dateToPreferredTimezone(value, "yyyy-MM-dd HH:mm:ss")
              : "";
          },
          ...createDateFilter(),
        },
      },
      {
        name: "BuyerId",
        label: "Buyer Id",
        options: {
          viewColumns: true,
        },
      },
      {
        name: "BuyerId",
        label: "Buyer Name",
        options: {
          viewColumns: true,
          customBodyRender: (value: any) => {
            return buyerList.find((b) => b?.BuyerId === value)?.BuyerName || "";
          },
        },
      },
      {
        name: "ContractIdList",
        label: "Contract Id List",
        options: {
          viewColumns: true,
        },
      },
      {
        name: "VerticalName",
        label: "Vertical Name",
        options: {
          viewColumns: true,
        },
      },
      {
        name: "SubVerticalName",
        label: "SubVertical Name",
        options: {
          viewColumns: true,
        },
      },
      {
        name: "StartDate",
        label: "Start Date",
        options: {
          filter: true,
          customBodyRender: (value: any, tableMeta: any) => {
            return DateTime.fromISO(value).toFormat("yyyy-MM-dd HH:mm:ss");
          },
          ...createDateFilter(),
        },
      },
      {
        name: "EndDate",
        label: "End Date",
        options: {
          filter: true,
          customBodyRender: (value: any, tableMeta: any) => {
            return DateTime.fromISO(value).toFormat("yyyy-MM-dd HH:mm:ss");
          },
          ...createDateFilter(),
        },
      },
      {
        name: "Timezone",
        label: "Timezone",
        options: {
          viewColumns: true,
        },
      },

      {
        name: "IsActive",
        label: "IsActive",
        options: {
          customBodyRender: (value: any) => <StatusIcon value={value} />,
          viewColumns: false,
          filter: true,
          ...booleanRadioFilter("Active"),
        },
      },
      {
        name: "UserId",
        label: "UserId",
        options: {
          viewColumns: true,
          filter: true,
        },
      },
    ];
  }, [buyerList, tableData]);

  useEffect(() => {
    getDeactivationSchedule();
  }, []);

  useEffect(() => {
    if (!buyerData && !loadingBuyers && (data || error)) {
      getBuyers();
    }
  }, [data, error]);

  const options: any = {
    searchOpen: true,
    filterType: "dropdown",
    sortFilterList: false,
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: "IsActive",
      direction: "desc",
    },
    /* onRowsDelete: (rowsDeleted, newData) => {
      const deleteIndex = rowsDeleted.data.map((d: typeof rowsDeleted) => d.dataIndex);
      const deactivationScheduleIdsToDelete = tableData?.filter((item, index) => deleteIndex.includes(index)).map((d => d?.DeactivationScheduleId));

      setConfirmation(
        () => {
          deleteDeactivationScheduleByIDs({
            variables: {
              deactivationScheduleIds: deactivationScheduleIdsToDelete
            }
          }).then((response) => {
            // evaluate responses here
            let allSuccess = true;
            let errorMsg = '';
            const responseData = JSON.parse(response.data?.LDPConfigMutationGroup?.DeleteDeactivationSchedule) || [];
            if (allSuccess) {
              toast.success(`Contract Capacity deleted.`);
              refetch();
              close();
            } else {
              toast.error(`Some items failed to delete: ${errorMsg}`);
            }
          }).catch(({ errors }) => {
            toast.error("Unexpected error!");
          });
        },
        {
          title: `Are you sure you want to delete?`,
          description: "",
        }
      );
    }, */
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box p={1}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  startIcon={<AddCircle />}
                  onClick={() => {
                    openModal({
                      title: "Deactivation Schedule",
                      icon: <EditIcon />,
                      iconColor: "green",
                      content: (
                        <DeactivationScheduleFormModal
                          action="create"
                          data={null}
                          close={closeModal}
                          refetch={refetch}
                        />
                      ),
                    });
                  }}
                >
                  Add Deactivation Schedule
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {loading || loadingBuyers || !data || buyerList.length === 0 ? (
            <ContentLoading showTip={true} />
          ) : (
            <LDPUIDataTable
              ldpTableId="tcpatype-list"
              restoreFilters={true}
              title={" "}
              data={tableData}
              columns={columnsMUI}
              options={options}
            />
          )}
        </Grid>
        <Modal />
      </Grid>
      <Confirmation />
    </Fragment>
  );
};

export default connect(
  (state: any) => ({
    DeactivationScheduleTab: state.tabsSection[TAB_CONTAINER],
  }),
  null
)(DeactivationScheduleList);

const Style = {
  Paper: {
    marginTop: "50px",
    padding: "30px",
    width: "100%",
  },
  TrafficLight: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    lineHeight: "24px",
    border: "#000 1px solid",
    borderRadius: "50%",
    margin: "auto 0",
  },
};
