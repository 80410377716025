import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import { useModal, ContentLoading, StatusIcon } from "../../components";
import { toast } from "react-toastify";
import { GET_SUBVERTICAL_RULE } from "../../common/models/subverticalRule";
import { GetSubVerticalRule, GetSubVerticalRule_LDPConfigQueryGroup_GetSubVerticalRule } from "../../common/models/types/GetSubVerticalRule";
import { AddCircle } from "@material-ui/icons";
import LaunchIcon from "@material-ui/icons/Launch";
import { IconButton, Button, Grid, Box } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';

import { signalBuyerRefreshCarriedOut } from "../../state/buyerSectionReducer";
import { updateTabContainers } from "../../state/tabsReducer";
import { TabProps } from "../../components/tabs";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { booleanRadioFilter, createDateFilter } from "../../components/customMuiDatatableFilter";
import { GoogleUserMeta } from "../../common/utils/googleUserMeta";
import UndersoldModal from "./undersoldModal";
import SubVerticalGlobalDefaultsList from "./subVerticalGlobalDatatables";


interface SubVerticalGlobalDefaultProps {
  
}

const TAB_CONTAINER = "subvert-global-defs-list-tabs";

const SubVerticalGlobalDefault = ({  }: SubVerticalGlobalDefaultProps) => {
  const googleUserMeta = GoogleUserMeta();


  return (
    <Grid container spacing={1}>
      <SubVerticalGlobalDefaultsList />
    </Grid>
  );
};

export default connect((state: any) => ({
  
}), null)(SubVerticalGlobalDefault);

