import { Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { GoogleUserMeta } from "../../common/utils/googleUserMeta";
import TcpaTypesList from "./tcpaTypeDatatables";

interface TcpaTypeProps {}

const TAB_CONTAINER = "subvert-global-defs-list-tabs";

const TcpaType = ({}: TcpaTypeProps) => {
  const googleUserMeta = GoogleUserMeta();

  return (
    <Grid container spacing={1}>
      <TcpaTypesList />
    </Grid>
  );
};

export default connect((state: any) => ({}), null)(TcpaType);
