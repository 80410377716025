import { Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { GoogleUserMeta } from "../../../common/utils/googleUserMeta";
import DeactivationScheduleList from "./deactivationScheduleDatatables";

interface TcpaTypeProps {}

const TAB_CONTAINER = "subvert-global-defs-list-tabs";

const DeactivationSchedule = ({}: TcpaTypeProps) => {
  const googleUserMeta = GoogleUserMeta();

  return (
    <Grid container spacing={1}>
      <DeactivationScheduleList />
    </Grid>
  );
};

export default connect((state: any) => ({}), null)(DeactivationSchedule);
