import { useLazyQuery } from "@apollo/react-hooks";
import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { AddCircle, Edit as EditIcon, History as HistoryIcon } from '@material-ui/icons';
import LaunchIcon from "@material-ui/icons/Launch";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { ContentLoading, LDPUIDataTable, StatusIcon, useModal } from "../../components";
import { RootState } from "../../state";
import { toast } from "react-toastify";
import { GET_SUBVERTICAL_RULE } from "../../common/models/subverticalRule";
import { GetSubVerticalRule, GetSubVerticalRule_LDPConfigQueryGroup_GetSubVerticalRule } from "../../common/models/types/GetSubVerticalRule";

import HistoryModal from "../history/HistoryModal";
import "react-table/react-table.css";
import { updateTabContainers } from "../../state/tabsReducer";
import { TabProps } from "../../components/tabs";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { booleanRadioFilter, createDateFilter } from "../../components/customMuiDatatableFilter";
import { GoogleUserMeta } from "../../common/utils/googleUserMeta";
import UndersoldModal from "./undersoldModal";

interface UndersoldListProps {
  undersoldTabs: any;
  undersoldRecordSaved: boolean;
  dispatch: Function;
  formsSection: any;
}

const TAB_CONTAINER = "undersold-list-tabs";

const UndersoldList = ({ undersoldTabs, undersoldRecordSaved, dispatch, formsSection }: UndersoldListProps) => {
  const [getAllUndersoldSettings, { data, error, loading, called, refetch }] = useLazyQuery<GetSubVerticalRule>(GET_SUBVERTICAL_RULE, { fetchPolicy: 'network-only' });
  const [ruleList, setRulesList] = useState<GetSubVerticalRule_LDPConfigQueryGroup_GetSubVerticalRule[]>([]);

  const googleUserMeta = GoogleUserMeta();
  const { Modal, closeModal, openModal, setContent } = useModal();

  const handleCloseTab = (tabs: TabProps[]) => {
    dispatch(
      updateTabContainers({
        [TAB_CONTAINER]: {
          tabFocus: (tabs.length - 1),
          tabs: tabs,
        }
      })
    );
  };

  const addTab = (title: string, content: JSX.Element) => {
    var currentTabs = undersoldTabs.tabContainers[TAB_CONTAINER].tabs;
    dispatch(updateTabContainers({
      [TAB_CONTAINER]: {
        tabFocus: currentTabs.length,
        tabs: [
          ...currentTabs,
          {
            tabId: `undersold-${currentTabs.length}`,
            title,
            content,
            closeTab: handleCloseTab
          },
        ],
      }
    }));
  }

  const columnsMUI = [
    {
      name: "SubVerticalRuleId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (            
            <>
            <Tooltip placement="top" title={
              <React.Fragment>
                <Typography color="textPrimary">Edit Buyer Filter</Typography>
              </React.Fragment>
            }>
              <IconButton
              onClick={() => {
                openModal({
                  title: "Edit Undersold Rule",
                  icon: <EditIcon />,
                  iconColor: "green",
                  content: (
                    <UndersoldModal
                      entryId={value}
                      close={closeModal}
                      refetch={() => {
                        if(refetch){
                          refetch();
                        } else {
                          getAllUndersoldSettings({
                            variables: {
                              isActive: true,
                            }
                          });
                        }
                      }}
                      dispatch={dispatch}
                      googleUserMeta={googleUserMeta}
                    />
                  ),
                });
              }}
            >
              <LaunchIcon />
            </IconButton>
            </Tooltip>

            <Tooltip placement="top" title={
                <React.Fragment>
                  <Typography color="textPrimary">History</Typography>
                </React.Fragment>
              }>
                <IconButton
                  onClick={() => {                    
                    openModal({
                      title: `Sub Vertical Rule ID#${value} History`,
                      icon: <HistoryIcon />,
                      iconColor: "orange",                                          
                      content: (
                        <HistoryModal 
                          ConfigName="SubVerticalRule" 
                          ConfigId={value} 
                          TabContainerId={`history-sub-vertical-rule-${value}-list-tabs`} 
                          close={closeModal} 
                          isDocker={false}
                          formId={`history-sub-vertical-rule-${value}`} />
                      ),
                    });
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
          </>
          );
        },
      },
    },
    // {
    //   name: "SubVerticalRuleId",
    //   label: "SubVerticalRule ID",
    //   options: {
    //     display: false,
    //     viewColumns: false,
    //     filter: false,
    //   },
    // },
    {
      name: "VerticalName",
      label: "Vertical",
      options: {
        viewColumns: true,
        filter: true,
        filterType: 'dropdown',
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: "SubVerticalName",
      label: "Sub-Vertical",
      options: {
        viewColumns: true,
        filter: true,
        filterType: 'dropdown',
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: "UserId",
      label: "Modified Last By",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
    {
      name: "ModifiedDate",
      label: "Modified Last Date",
      options: {
        filter: true,        
        customBodyRender: (value: any) => {
            return value
            ? dateToPreferredTimezone(value, "yyyy-MM-dd")
            : "";
        },
        ...createDateFilter()
      }
    },
    {
      name: "UndersoldThreshold",
      label: "Undersold Price",
      options: {
        viewColumns: true,
        filter: false,
      },
    },
    {
      name: "CatchAllThreshold",
      label: "Catch-All Price",
      options: {
        viewColumns: true,
        filter: false,
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        viewColumns: false,
        filter: true,
        ...booleanRadioFilter('Active'),
      },
    },
  ];

  useEffect(() => {
    getAllUndersoldSettings();
  }, []);

  useEffect(() => {
    if(data && !error){
      const list: GetSubVerticalRule_LDPConfigQueryGroup_GetSubVerticalRule[] = [];
      data.LDPConfigQueryGroup?.GetSubVerticalRule?.forEach(r => {
        if(r) list.push(r);
      });
      setRulesList(list);
    }

    if (data && undersoldRecordSaved) {
      toast.info("Refreshing List");
      refetch().finally(() => {
        toast.dismiss();
        // dispatch(signalBuyerRefreshCarriedOut());
      });
    }
  }, [data]);

  const tableData = useMemo(() => {
    return [ ...(ruleList || []) ];
  }, [ruleList]);

  const options: any = {
    searchOpen: true,
    filterType: "dropdown",
    sortFilterList: false,
    selectableRows: "none",
    responsive: "vertical",        
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  openModal({
                    title: "Add Undersold Rule",
                    icon: <EditIcon />,
                    iconColor: "green",
                    content: (
                      <UndersoldModal
                        close={() => {
                          closeModal();
                          // refetch();
                        }}
                        refetch={refetch}
                        dispatch={dispatch}
                        googleUserMeta={googleUserMeta}
                      />
                    ),
                  });
                }}
              >
                Set Undersold
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {loading && <ContentLoading showTip={true} />}
        <LDPUIDataTable
          ldpTableId="undersold-list"
          restoreFilters={true}
          title={""}
          data={tableData}
          columns={columnsMUI}
          options={options}
        />
      </Grid>
      <Modal />
    </Grid>
  );
};

export default connect((state: RootState) => ({
  // undersoldRecordSaved: state.undersoldSection.undersoldRecordSaved,
  formsSection: state.formsSection,
  undersoldTabs: state.tabsSection,
}), null)(UndersoldList);

const Style = {
  Paper: {
    marginTop: "50px",
    padding: "30px",
    width: "100%",
  },
  TrafficLight: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    lineHeight: "24px",
    border: "#000 1px solid",
    borderRadius: "50%",
    margin: "auto 0",
  },
};
