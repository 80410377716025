import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AddCircle,
  Edit as EditIcon,
  History as HistoryIcon,
} from "@material-ui/icons";
import LaunchIcon from "@material-ui/icons/Launch";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { GET_TCPA_TYPE } from "../../common/models/tcpaType";
import {
  GetTcpaType,
  GetTcpaType_LDPConfigQueryGroup_GetTcpaType,
} from "../../common/models/types/GetTcpaType";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { GoogleUserMeta } from "../../common/utils/googleUserMeta";
import { ContentLoading, StatusIcon, useModal } from "../../components";
import {
  booleanRadioFilter,
  createDateFilter,
} from "../../components/customMuiDatatableFilter";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import HistoryModal from "../history/HistoryModal";
import TcpaModal from "./tcpaModal";

const TAB_CONTAINER = "subvertical-global-defaults-list-tabs";

interface TcpaTypesListProps {
  TcpaTypesTab?: any;
  dispatch?: Function;
}

const TcpaTypesList = ({ TcpaTypesTab, dispatch }: TcpaTypesListProps) => {
  const [
    getTcpaType,
    { data, error, loading, called, refetch },
  ] = useLazyQuery<GetTcpaType>(GET_TCPA_TYPE, {
    fetchPolicy: "network-only",
  });
  const [tcpaTypesList, setTcpaTypesList] = useState<
    (GetTcpaType_LDPConfigQueryGroup_GetTcpaType | null)[] | null
  >();
  const googleUserMeta = GoogleUserMeta();
  const { Modal, closeModal, openModal, setContent } = useModal();

  const columnsMUI = [
    {
      name: "TcpaTypeId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">Edit TCPA Type</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    openModal({
                      title: "Edit TCPA Type",
                      icon: <EditIcon />,
                      iconColor: "green",
                      content: (
                        <TcpaModal
                          entryId={value}
                          rowData={Object.fromEntries(
                            columnsMUI.map((it: any, index: number) => [
                              it.name,
                              tableMeta.rowData[index],
                            ])
                          )}
                          close={() => {
                            closeModal();
                            if (refetch) refetch();
                            else
                              getTcpaType();
                          }}
                          refetch={refetch}
                          dispatch={dispatch as Function}
                          googleUserMeta={googleUserMeta}
                        />
                      ),
                    });
                  }}
                >
                  <LaunchIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">History</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    openModal({
                      title: `Tcpa Type ID#${value} History`,
                      icon: <HistoryIcon />,
                      iconColor: "orange",
                      content: (
                        <HistoryModal
                          ConfigName="TcpaType"
                          ConfigId={value}
                          TabContainerId={`tcpa-type-${value}-list-tabs`}
                          close={closeModal}
                          isDocker={false}
                          formId={`tcpa-type-${value}`}
                        />
                      ),
                    });
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    // {
    //   name: "TcpaTypeId",
    //   label: "Id",
    //   options: {
    //     viewColumns: true,
    //   },
    // },
    {
      name: "TcpaType",
      label: "Tcpa Type",
      options: {
        viewColumns: true,
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        filter: true,
        customBodyRender: (value: any) => {
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        ...createDateFilter(),
      },
    },
    {
      name: "ModifiedDate",
      label: "Modified Date",
      options: {
        filter: true,
        customBodyRender: (value: any) => {
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        ...createDateFilter(),
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        viewColumns: false,
        filter: true,
        ...booleanRadioFilter("Active"),
      },
    },
    {
      name: "UserId",
      label: "UserId",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
  ];

  useEffect(() => {
    getTcpaType();
  }, []);

  useEffect(() => {
    if (data && !error) {
      const list = data.LDPConfigQueryGroup?.GetTcpaType;
      setTcpaTypesList(list);
    }
  }, [data]);

  const tableData = useMemo(() => {
    return [...(tcpaTypesList || [])];
  }, [tcpaTypesList]);

  const options: any = {
    searchOpen: true,
    filterType: "dropdown",
    sortFilterList: false,
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  openModal({
                    title: "TCPA Type",
                    icon: <EditIcon />,
                    iconColor: "green",
                    content: (
                      <TcpaModal
                        close={() => {
                          closeModal();
                          refetch();
                        }}
                        refetch={refetch}
                        dispatch={dispatch as Function}
                        googleUserMeta={googleUserMeta}
                      />
                    ),
                  });
                }}
              >
                Add TCPA Type
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {loading && <ContentLoading showTip={true} />}
        <LDPUIDataTable
          ldpTableId="tcpatype-list"
          restoreFilters={true}
          title={""}
          data={tableData}
          columns={columnsMUI}
          options={options}
        />
      </Grid>
      <Modal />
    </Grid>
  );
};

export default connect(
  (state: any) => ({
    TcpaTypesTab: state.tabsSection[TAB_CONTAINER],
  }),
  null
)(TcpaTypesList);

const Style = {
  Paper: {
    marginTop: "50px",
    padding: "30px",
    width: "100%",
  },
  TrafficLight: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    lineHeight: "24px",
    border: "#000 1px solid",
    borderRadius: "50%",
    margin: "auto 0",
  },
};
