import gql from "graphql-tag";

export const GET_DEACTIVATION_SCHEDULE = gql`
  query GetDeactivationSchedule(
    $where: String
  ) {
    LDPConfigQueryGroup {
      GetDeactivationSchedule(
        where: $where
        limit: 9999
      ) {
        BuyerId
        ContractIdList
        CreatedDate
        DeactivationScheduleId
        EndDate
        IsActive
        ModifiedDate
        StartDate
        SubVerticalName
        Timezone
        UserId
        VerticalName
        Name
      }
    }
  }
`;

export const DELETE_DEACTIVATION_SCHEDULE = gql`
  mutation DeleteDeactivationSchedule($deactivationScheduleIds: [ID]!) {
    LDPConfigMutationGroup {
      DeleteDeactivationSchedule(DeactivationScheduleIds: $deactivationScheduleIds)
    }
  }
`;

export const SAVE_DEACTIVATION_SCHEDULE = gql`
  mutation SaveDeactivationSchedule($input: DeactivationScheduleInputType!){
    LDPConfigMutationGroup {
      SaveDeactivationSchedule(DeactivationScheduleInput: $input) {
        DeactivationScheduleId
      }
    }
  }
`;