import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { SAVE_TCPA_TYPE } from "../../common/models/tcpaType";
import { GoogleUserMetaProp } from "../../common/utils/googleUserMeta";
import { queryLoad } from "../../components";
import { eventTracker as tracker } from "../../components/tracker";
import { RootState } from "../../state";
import { DataCacheState } from "../../state/dataCacheReducer";
import { TcpaTypeTypeInput } from "../../types/graphql-global-types";

interface TcpaTypeFormProps {
  entryId?: number;
  rowData?: any;
  close: Function;
  refetch: Function;
  dispatch: Function;
  googleUserMeta?: GoogleUserMetaProp;
  entityDataCache?: DataCacheState;
}

const formError = {
  TcpaType: {
    required: {
      value: true,
      message: "Tcpa Type is required.",
    },
  },
};

const WrappedTcpaFormModal = ({
  entryId,
  rowData,
  close,
  refetch,
  dispatch,
  googleUserMeta,
  entityDataCache,
}: TcpaTypeFormProps) => {
  const [saveTcpaType] = useMutation(SAVE_TCPA_TYPE);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    reset,
  } = useForm<TcpaTypeTypeInput>();
  const classes = useStyles();

  const [pageLoading, setPageLoading] = useState<boolean>(false);

  // const [isResetOnEdit, setIsResetOnEdit] = useState<boolean>(false);
  // useEffect(() => {
  //   console.log(ruleData);
  //   if (rowData && !ruleData) {
  //     const inputData = {
  //       UserId: rowData?.UserId,
  //       VerticalName: rowData?.VerticalName,
  //       SubVerticalName: rowData?.SubVerticalName,
  //       MaxNonExclusiveSaleCount: rowData?.MaxNonExclusiveSaleCount,
  //     };

  //     //setRuleData(inputData);
  //     // reset(inputData);
  //     setIsResetOnEdit(true);
  //   }

  //   console.log(isResetOnEdit);

  const onSubmit = (tcpaTypeInput: TcpaTypeTypeInput) => {
    setPageLoading(true);
    saveTcpaType({
      variables: {
        tcpaTypeData: tcpaTypeInput,
      },
    }).then((response: any) => {
      setPageLoading(false);
      eventTracker({ tcpaTypeData: tcpaTypeInput });
      toast.success("TCPA Type successfully saved.");

      // refetch();
      // dispatch(signalBuyerSaved());
      close();
    });
  };

  const eventTracker = ({ submittedData }: any) => {
    const blockers: any = { "@": "[at]", ".": "[dot]" };
    tracker({
      name: "TCPA Type Rule",
      caption: "Track TCPA Type Changes",
      values: {
        ...submittedData,
        email:
          googleUserMeta?.email?.replace(/\@|\./g, (it) => blockers[it]) ??
          null,
      },
    });
  };

  return (
    <Paper className={classes.contrainer}>
      {queryLoad([!!pageLoading], []) || (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs={12} key="max-per-lead">
              <TextField
                hidden
                inputRef={register}
                name="TcpaTypeId"
                value={rowData?.TcpaTypeId ?? 0}
                variant="outlined"
              />
              <TextField
                inputRef={register(formError.TcpaType)}
                error={errors.TcpaType && true}
                helperText={errors.TcpaType && errors.TcpaType?.message}
                name="TcpaType"
                label="Tcpa Type"
                defaultValue={rowData?.TcpaType ?? ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={rowData?.IsActive ?? true}
                    name="IsActive"
                    color="primary"
                  />
                }
                label="Is Active"
              />
            </Grid>
            <Grid item xs={12} key="no-content-divider">
              <Divider />
            </Grid>
            <Grid item xs={6} key="but-1">
              <Button
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => {
                  close();
                  if (refetch) refetch();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} key="but-2">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  entityDataCache: state.entityDataCache,
});

export default connect(mapStateToProps, null)(WrappedTcpaFormModal);
